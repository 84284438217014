import { FC, useEffect, useRef, useState } from 'react'

import { useTranslate } from '@ayub-begimkulov/i18n'
import { Avatar, Flex, Text } from '@radix-ui/themes'
import Arrows from 'assets/images/caret-sort.svg?react'
import cx from 'clsx'
import { CompaniesMenu } from 'components/template/Sidebar/SidebarMenuPro/CompaniesMenu'
import { useSidebar } from 'hooks/useSidebar'
import { CompanySuggestion } from 'pro/companies/api/interfaces'
import { useGetCompanyInfo } from 'pro/companies/queries'
import { useCompanyId } from 'pro/companies/store'
import { Animation, AnimationTypes } from 'ui/Animation'
import { Loader, LoaderTypes } from 'ui/Loader'

import classes from './CardCompany.module.scss'

interface Props {
  company?: CompanySuggestion
  isLoading: boolean
}

export const CardCompany: FC<Props> = ({ company, isLoading }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { getCompanyInfo } = useGetCompanyInfo()
  const { companyId } = useCompanyId()
  const refBoxWidthCompanies = useRef<HTMLLIElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)
  const { collapsed } = useSidebar()

  const translate = useTranslate()

  const onClickOpen = () => {
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    if (collapsed) {
      setOpen(false)
    }
  }, [collapsed])

  const handleOutsideClick = (e: MouseEvent) => {
    if (refWrapper.current && !refWrapper.current.contains(e.target as Node)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    setOpen(false)
  }, [companyId])

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [open])

  return (
    <div ref={refWrapper}>
      <li
        className={cx(classes.item, { [classes.collapsed]: collapsed })}
        onClick={onClickOpen}
        ref={refBoxWidthCompanies}
      >
        {isLoading && <Loader type={LoaderTypes.SpinnerCenter} />}
        {!isLoading && (
          <Flex align="center" className={classes.cardCompany} justify="between">
            <Flex gap="2">
              <Avatar fallback={company ? company.name[0] : 'K'} radius="medium" size="4" />
              <div className={classes.inf}>
                <Text className={classes.name} weight="medium">
                  {company && company.name.length <= 20 ? company?.name : `${company?.name.slice(0, 21)}...`}
                </Text>
                <Flex color="gray">
                  <Text className={classes.text} color="gray">
                    {company?.formOwnership === 'LLC'
                      ? translate('pro.auth.LLC')
                      : company?.formOwnership === 'IE'
                        ? translate('pro.auth.IE')
                        : ''}{' '}
                    • {company && company.name.length <= 20 ? company?.name : `${company?.name.slice(0, 21)}...`}
                  </Text>
                </Flex>
              </div>
            </Flex>
            <Arrows className={classes.arrows} />
          </Flex>
        )}
      </li>
      <Animation className={classes.animation} type={AnimationTypes.Opacity}>
        {open && <CompaniesMenu company={company} getCompanyInfo={getCompanyInfo || []} setOpen={setOpen} />}
      </Animation>
    </div>
  )
}

export const parametersCompanyAndSetting = [
  {
    id: 0,
    name: 'Точки продаж',
    navigate: {
      to: '/shops',
    },
  },
  {
    id: 1,
    name: 'Настройки компании',
    navigate: {
      to: '/settings-company',
    },
  },
  {
    id: 2,
    name: 'Список сотрудников',
    navigate: {
      to: '/list-employee',
    },
  },
  {
    id: 3,
    name: 'Роли и права доступа',
    navigate: {
      to: '/roles-list',
    },
  },
  {
    id: 4,
    name: 'Справочники',
    navigate: {
      to: '/manuals',
    },
  },
]

import { useNavigate } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { IS_ADMIN, IS_PRO } from 'constants/env'
import { AuthSteps } from 'pro/auth/interfaces'
import { useAuth } from 'pro/auth/store'

export const useLogout = () => {
  const navigate = useNavigate()
  const { setAuthStep } = useAuth()

  const logout = () => {
    setAxiosAuthorizationToken(null)
    storage.clearToken()

    if (IS_ADMIN) {
      navigate({ to: '/login' })
    } else if (IS_PRO) {
      setAuthStep(AuthSteps.EnterPhone)
      navigate({ to: '/login' })
    }
  }

  return { logout }
}

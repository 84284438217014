import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  profileAdmin: NoParamEndpointConstructor
  profilePro: NoParamEndpointConstructor
}

export const profileEndpoints: Endpoints = {
  profileAdmin: () => 'auth/me',
  profilePro: () => 'auth/user/me',
}

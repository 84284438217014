import { FC, useEffect, useState } from 'react'

import { MenuItemListPro } from 'components/template/Sidebar/MenuItemListPro'
import { useTranslate } from 'config/i18n'
import { parametersCompanyAndSetting } from 'constants/parametersCompanyAndSetting'
import { CompanySuggestion } from 'pro/companies/api/interfaces'
import { CardCompany } from 'pro/companies/components/CardCompany'
import { useGetOneCompanyInfo } from 'pro/companies/queries'
import { useCompanyId } from 'pro/companies/store'

import classes from './MenuMarketPro.module.scss'

export const MenuMarketPro: FC = () => {
  const { companyId } = useCompanyId()
  const { oneCompanyInfo, isLoading } = useGetOneCompanyInfo(companyId)
  const [company, setCompany] = useState<CompanySuggestion>()
  const translate = useTranslate()

  useEffect(() => {
    setCompany(oneCompanyInfo)
  }, [oneCompanyInfo])

  return (
    <ul className={classes.menu}>
      <CardCompany company={company} isLoading={isLoading} />
      <MenuItemListPro
        items={parametersCompanyAndSetting}
        translate={translate('pro.companySetting.companyAndSettings')}
      />
    </ul>
  )
}

import { FC, lazy, Suspense } from 'react'
import { ToastContainer } from 'react-toastify'

import { I18NProvider } from '@ayub-begimkulov/i18n'
import { Theme } from '@radix-ui/themes'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet, useRouterState } from '@tanstack/react-router'
import { useAuthenticated } from 'admin/auth/handlers/useAuthenticated'
import { isAuthPages } from 'config/auth/isAuthPages'
import { isLoginPage } from 'config/auth/isLoginPage'
import { i18n, useReadyLang } from 'config/i18n'
import { queryClient } from 'config/queryClient'
import { ACCENT_COLOR, IS_DEV_MODE } from 'constants/env'
import { toastsProps } from 'constants/toasts'
import { AuthLayout } from 'layouts/AuthLayout'
import { MainLayout } from 'layouts/MainLayout'
import { Loader, LoaderTypes } from 'ui/Loader'

const TanStackRouterDevtools = IS_DEV_MODE
  ? lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )
  : () => null

export const RootLayout: FC = () => {
  const { readyI18n } = useReadyLang()
  const { authenticated } = useAuthenticated()
  const { location, isLoading } = useRouterState()
  const Layout = (isLoginPage(location) && !authenticated) || isAuthPages(location) ? AuthLayout : MainLayout

  const isLoadingPage = isLoading && location.pathname === '/login'

  return (
    <I18NProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Theme accentColor={ACCENT_COLOR}>
          <Layout>
            {!readyI18n || isLoadingPage ? <Loader type={LoaderTypes.SpinnerCenter} /> : <Outlet />}
            <ToastContainer {...toastsProps} />
            <ReactQueryDevtools initialIsOpen={false} position="bottom" />
            <Suspense>
              <TanStackRouterDevtools />
            </Suspense>
          </Layout>
          <div id="portal" />
          <div id="portal2" />
        </Theme>
      </QueryClientProvider>
    </I18NProvider>
  )
}

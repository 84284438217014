import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getErrorMessage } from 'packages/react-query'
import { proCompanyApi } from 'pro/companies/api/methods'

export const useGetCompanyInfo = () => {
  const queryClient = useQueryClient()

  const { data, isLoading, error } = useQuery({
    queryKey: ['authCompany'],
    queryFn: () => proCompanyApi.getCompanyInfo(),
    meta: {
      error: (apiError) => ({
        showToast: false,
        message: getErrorMessage(apiError),
      }),
    },
  })

  const clearCompaniesState = () => {
    queryClient.removeQueries({ queryKey: ['authCompany'] })
  }

  return {
    getCompanyInfo: data,
    isLoading,
    error,
    clearCompaniesState,
  }
}

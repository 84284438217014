import { FC } from 'react'

import ArrowIcon from 'assets/images/arrow.svg?react'
import cx from 'clsx'
import { IS_ADMIN, IS_PRO } from 'constants/env'
import { useSidebar } from 'hooks/useSidebar'

import { BottomMenu } from './BottomMenu'
import { Logo } from './Logo'
import classes from './Sidebar.module.scss'
import { SidebarMenu } from './SidebarMenu'
import { SidebarMenuPro } from './SidebarMenuPro'

export const Sidebar: FC = () => {
  const { collapsed, toggle } = useSidebar()

  return (
    <>
      <div className={cx(classes.borderIcon, { [classes.collapsed]: collapsed })}>
        <ArrowIcon className={cx(classes.arrowIcon, { [classes.collapsed]: collapsed })} onClick={toggle} />
      </div>

      <aside className={cx(classes.sidebar, { [classes.collapsed]: collapsed })}>
        <Logo />

        {IS_ADMIN && <SidebarMenu />}
        {IS_PRO && <SidebarMenuPro />}

        <BottomMenu />
      </aside>
    </>
  )
}

import { FC } from 'react'

import { ApiError } from 'packages/api'
import { getErrorMessage } from 'packages/react-query'

import classes from './error.module.scss'

interface ErrorProps {
  error?: ApiError | null
  message?: string
}

export const Error: FC<ErrorProps> = ({ error, message }) => (
  <div className={classes.error}>{message ? message : getErrorMessage(error)}</div>
)

import { useQuery } from '@tanstack/react-query'
import { profileApi } from 'admin/profile/api/methods'
import { useTranslate } from 'config/i18n'
import { IS_ADMIN, IS_PRO } from 'constants/env'
import { useIsAuth } from 'hooks/useIsAuth'
import { useQueryClient } from 'packages/react-query'

export const useProfile = () => {
  const auth = useIsAuth((state) => state.auth)
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const { isPending, error, data } = useQuery({
    queryKey: ['userData'],
    queryFn: async () => {
      if (IS_ADMIN) {
        return profileApi.getProfileAdmin()
      } else if (IS_PRO) {
        return profileApi.getProfilePro()
      }
    },
    enabled: auth,
    meta: { error: { showToast: false, message: translate('apiErrors.profile') } },
  })

  const clearProfileState = () => {
    queryClient.removeQueries({ queryKey: ['userData'] })
  }

  return {
    isLoading: isPending,
    error,
    profile: data,
    clearProfileState,
  }
}

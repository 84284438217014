import { axios } from 'config/axios'
import { GetOneData } from 'interfaces/api.interfaces'

import { profileEndpoints } from './endpoints'
import { Profile } from './interfaces'

const getProfileAdmin = async (): Promise<Profile> => {
  const { data } = await axios.request<GetOneData<Profile>>({
    method: 'GET',
    url: profileEndpoints.profileAdmin(),
  })
  return data?.data
}

const getProfilePro = async (): Promise<Profile> => {
  const { data } = await axios.request<Profile>({
    method: 'GET',
    url: profileEndpoints.profilePro(),
  })
  return data
}

export const profileApi = {
  getProfileAdmin,
  getProfilePro,
}
